/* You can add global styles to this file, and also import other style files */
$primary: #2C2F33;
// $danger: #dd0000;
// $success: lime;
// $warning: orange;
$light: hsl(0, 1%, 59%);
$secondary: #222529;
$dark: #181A1C;
$info: #497AF8;
@import "~bootstrap/scss/bootstrap-utilities";
$utilities: map-merge(
  $utilities,
  (
    "cursor": (
      property: cursor,
      values: auto default pointer wait text move help not-allowed
    ),
  )
);

@import "~bootstrap/scss/bootstrap";
//@import '~@angular/cdk/overlay-prebuilt.css';
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");
@import "~ngx-spinner/animations/ball-scale-multiple.css";



body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  background: #222529;
  color: #fff;
}


.font-12 {
    font-size: 12px;
}

.font-10 {
    font-size: 10px;
}

/**** For animating using animate.css ********/
.duration-1000 {
    animation-duration: 1s;
}

.progress-section-body {
    .card {
        .card-body {
            padding: 0.5rem;
        }

        .card-header {
            padding: 0.25rem 0.5rem;
        }
    }
}

table.mat-table {
    width: 100%;
}

.mat-table {
    font-family: inherit;

    .mat-cell,
    .mat-footer-cell {
        font-size: 13px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

/******** Smart View **************/
ul.nav-tabs {
    a.nav-link.active {
        font-weight: bolder;
    }
}

.tr-client-notified {
    background-color: #ababab;
    color: #000;
}

.tr-in-art-department {
    background-color: #891a40;
    color: #fff;

    a {
        color: #fff;
    }
}

.tr-at-client-for-approval {
    background-color: #18acdb;
    color: #fff;

    a {
        color: #fff;
    }
}

.tr-at-coop-compliance {
    background-color: #0d6885;
    color: #fff;

    a {
        color: #fff;
    }
}

.tr-art-approved-by-client {
    background-color: #9fe2bf;
    color: #000;
}

.tr-sent-for-deployment {
    background-color: #f8d14d;
    color: #000;
}

.tr-deployment-tested {
    background-color: #ff6900;
    color: #000;
}

.tr-deployed {
    background-color: #06293f;
    color: #fff;

    a {
        color: #fff;
    }
}

.tr-deploments-complete {
    background-color: #6f8faf;
    color: #000;

    a {
        color: #000;
    }
}

.tr-program-complete {
    background-color: #ee2f40;
    color: #fff;

    a {
        color: #fff;
    }
}

.tr-on-hold {
    background-color: #64176d;
    color: #fff;

    a {
        color: #fff;
    }
}

/************ Campaign Progress **********/
.bg-incomplete {
    background-color: rgba($color: #ff6900, $alpha: 0.9);
    color: #06293f;

    h6 {
        font-weight: bold;
    }
}

.bg-complete {
    background-color: rgba($color: #597d36, $alpha: 1);
    color: #fff;

    h6 {
        font-weight: bold;
    }

    p {
        font-size: 90%;
        margin-bottom: 0rem;
    }
}

.bg-gray {
    background-color: rgba($color: #ababab, $alpha: 1);
    color: #000;

    h6 {
        font-weight: bold;
    }

    p {
        font-size: 90%;
        margin-bottom: 0rem;
    }
}

.btn-drivonic-cp {
    color: #fff;
    background-color: #06293f;
    border-color: #06293f;

    &:hover,
    &:active {
        // font-weight: bolder;
        color: #fff;
    }
}

.fixed-campaign-header {
    position: fixed;
    top: 85px;
    height: auto;
    left: 0;
    right: 0;
    z-index: 100;
}

.margin-top-10 {
    margin-top: 10rem;
}

.sv-table {

    // height: 60vh;
    // overflow-y: auto;
    th,
    td {
        padding: 0.5rem;
    }

    th {
        a {
            font-weight: bold;
        }
    }

    td {
        font-size: 90%;
    }
}

label.required:after {
    content: ' *';
    color: red;
}

.mat-drawer-inner-container::-webkit-scrollbar {
    display: none;
}


.mat-drawer-container {
    background: none;
    z-index: unset !important;

    .mat-drawer-content {
        z-index: unset !important;
    }
}

.cdk-overlay-container {
    z-index: 2000;
}

.swal-table {
  font-size: 12px;
}
